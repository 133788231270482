import $ from "jquery";
import http from "@/api/axios";
export default {
    name: "",
    components: {},
    data() {
        return {
            activeCase: false,
            type: 0,
            caseList: [{
                subtitle: '火益-企业数字化转型工具（应用层）',
                mainBody: `<p style="text-indent:40px">火益云(HOMYi)是一个让全新概念的企业快速触网，实现数字化升级的云服务互联网统一云应用产品平台，以公有云（SaaS）或私有云形式，为企业提供互联网+企业数字化转型升级解决方案，让企业现有模式与互联网深度结合，为利用互联网、大数据和人工智能技术，赋能传统业务的企业进行数字化与智能化，促进业务模式与互联网深度融合，实现企业数字化升级。火益云被工业与信息化部推荐为中小企业数字化运营工具，也是四川省经信厅选定的21家云服务之一。赋能，快速实现数字化转型。</p>
        <p style="text-indent:40px">火益支持在线图文排版、PC官网编辑、微信图文编辑、微信微博推广、微信微博管家、平台和活动数据监测、APP生产等功能，是集编辑、营销和推广于一体的实用型应用工具。</p>
        <p style="text-indent:40px">火益拥有丰富的业务模式，除传统的微商城、三级分销系统、防伪系统和积分系统外，还新增了预售系统实现饥饿营销，提前回笼资金；智能客服系统在线智能应答；售后系统提升用户体验；以及礼品卡系统引流线下用户。打造线上线下相结合的售前售中售后体系，为不同行业提供互联网+转型升级服务。</p>
        <p style="text-indent:40px">火益还支持多渠道统一管理，包括统一的内容管理、统一的信息发布以及统一的客户管理，一个平台同时管理多个渠道，形成完整的数据链条。</p>
        <p style="font-weight:bold">产品特点：</p>
        <p style="text-indent:40px">1、CCTV2《经济半小时》：“火益是让传统企业与互联网深度融合的产品”</p>
        <p style="text-indent:40px">2、国家工业和信息化部《中小企业数字化赋能服务产品及活动推荐目录（第一期）》（四川省仅3家）</p>
        <p style="text-indent:40px">3、四川省经济和信息化厅认定的21个云服务商之一</p>
        <p style="text-indent:40px">4、四川省抗击新型冠状病毒肺炎疫情的推荐云服务应用企业</p>
        <p style="text-indent:40px"><span style="font-weight:bold">大数据收集：</span>企业与用户的各业务模式，均嵌入对用户行为的数据收集，使用越久企业对用户画像就越完整。（详见数据交换）</p>
        <p style="text-indent:40px"><span style="font-weight:bold">人工智能增强：</span>随着数据收集的增多，系统后台的人工智能系统不断优化算法，对于用户的评判、趋势、喜好、标签分析越准确，而这些数据资产又不断构建企业数据护城河。</p>
        <p style="text-indent:40px"><span style="font-weight:bold">全渠道统一管理：</span>（列点）微信管家，微博管家，Facebook、Google Shopping，微官网编辑器，官方网站编辑器，APP生成器，统一支付（详见统一支付产品）支付助手</p>
        <p style="text-indent:40px"><span style="font-weight:bold">丰富的业务模式：</span>（列点）微商城，积分系统，预售系统，售后系统，客服系统，防伪系统，礼品卡系统，三级分销系统</p>
        <p style="text-indent:40px"><span style="font-weight:bold">多样的营销活动：</span>（列点）砍价、助力、秒杀商品、刮刮卡、防伪卡、礼券领取、拼团、快速礼券、集字、集图、积分商品、大转盘、摇一摇、老虎机、旅游类商品、众筹、礼品兑换卡、PM2.5大逃亡、叠了个塔、单身狗的复仇、夺宝、起床困难户、滑雪大挑战等</p>
        <p style="text-indent:40px">火益云被应用于10000家企业，10余个地方政府，帮助企业创造长远的数字价值。</p>
`
            }, {
                subtitle: '火益-大数据共享交换平台（数据层）',
                mainBody: `<p style="text-indent:40px">基于轻量级的SCDF平台，结合Kubernetes平台的容器调度能力，打造的大数据交换平台。提供目录管理、数据交换、质量管理、资源发布等一系列交换平台功能。</p>
        <p style="text-indent:40px">该大数据交换平台被火益云使用，同时被应用于公司客户的项目之中，为客户增加大数据维度的能力。</p>`
            }, {
                subtitle: '智慧旅游平台',
                mainBody: `<p style="text-indent:40px">构建涵盖内部已建业务系统数据、涉旅数据、涉旅单位数据、运营商、互联网旅游数据等的旅游大数据资源中心，并进行数据的深度融合，实现数据间关联共享，开展数据深度挖掘分析，为政府、企业和游客提供决策和咨询服务，助推旅游数字化、网络化、智能化取得明显进展，旅游公共信息服务水平显著提高，旅游在线营销能力全面发展，行业监管能力进一步增强，旅游电子政务支撑行业治理现代化坚实有力，信息化引领旅游业转型升级取得明显成效</p>
        <p style="text-indent:40px">该产品在成都青羊区、都江堰等实现了地区级应用，在都江堰、武侯祠、金沙遗址、杜甫草堂、以及成都数字3000年实现了专业应用。</p>
        组成部分
        <p style="text-indent:40px"><span style="font-weight:bold">旅游大数据资源中心：</span>通过数据采集系统和统一调度系统将内部已建业务系统数据、涉旅数据、涉旅单位数据、运营商、互联网旅游数据等文旅相关数据纳入到资源中心,为后续的数据治理和分析提供可量化的数据支撑，同时提供数据共享服务</p>
        <p style="text-indent:40px"><span style="font-weight:bold">旅游宣传营销平台：</span>搭建旅游宣传营销平台，以旅游项目为特色，实现旅游产品推广、旅游成果展示、旅游资源与旅游投资对接，旅游在建项目服务等种功能。</p>
        <p style="text-indent:40px"><span style="font-weight:bold">旅游大数据分析平台：</span>根据采集的旅游数据进行行业的监管分析，主要对旅游企业及相关从业人员的行业监督管理，特别是旅游服务质量、旅游安全、旅游统计等方面工作的管理，平台通过从网上采集、内部整合、涉旅接入等多方式采集的旅游及相关数据进行统计、监测、分析、预测、预警等功能。</p>
        <p style="text-indent:40px"><span style="font-weight:bold">旅游电子政务平台：</span>搭建旅游电子政务平台，实现涉旅安全预警、游客投诉分析预警、涉旅商户违规预警、旅游市场景气指数、涉旅商户监测与分析、旅游信用大数据、旅游产业分析、文博企业分析等功能。</p>
        `
            }, {
                subtitle: '火益-统一支付系统（应用层）',
                mainBody: `<p style="text-indent:40px">解决支付账号与支付方式分散、不易管理的问题；对内，提供统一支付接口，对接所有线上线下的业务系统，统一管理支付流水，支付账号；对外，提供多形式、多渠道的支付方式，满足不同场景的需要。</p>
        <p style="text-indent:40px">该产品被应用于公司的火益云及其它对营销、无人售货、智慧停车等对C端有收费需求的用户场景中。</p>
        <span style="font-weight:bold">特点：</span>
        <p style="text-indent:40px">整合以下接口：银联支付、微信支付、支付宝、跨境支付（PayPal、钱海、Stripe等）、数字钱包。支持几乎全球所有主流信用卡支付、主流线上及线下的支付能力。</p>
        <p style="text-indent:40px">提供标准API：0代码开发即可实现线上线下收单，也标准化支持线上应用场景开发，线下应用场景开发。</p>
        <span style="font-weight:bold">功能：</span>
        <p style="text-indent:40px">1、公众号支付、网页扫码支付、手机端被动扫码支付。</p>
        <p style="text-indent:40px">2、支付流水状态查询接口，退款申请及退款状态查询接口。</p>
        <p style="text-indent:40px">3、支付流水查询及报表统计功能。</p>
        <p style="text-indent:40px">4、积分查询统计功能。</p>
        <p style="text-indent:40px">5、礼券查询及统计功能。</p>
        <p style="text-indent:40px">6、平台用户管理及授权管理。</p>
        <p style="text-indent:40px">7、应用系统接口授权管理。</p>`
            }, {
                subtitle: '火益-视联网与人工智能（应用层）',
                mainBody: `<p style="text-indent:40px">视频在特定情况下替代其他互联网媒介去串联人、设备、信息等，并逐渐形成以视频为主要信息介质和内容载体的互联网形态，可以将其称之为“视联网”，</p>
        <p style="text-indent:40px">通过AI算法可以实现计算机自动识别视频中的海量信息，以计算机超过人类数万倍的速度去标识出视频中的可用信息，甚至做出人难以理解却准确的识别判断。视频识别能力变得更快、更准、维度更多。</p>
        <p style="text-indent:40px">该产品在智慧城市、交易中心、飞行测试视频分析、智慧旅游等部分有成功应用。</p>
        <span style="font-weight:bold">产品特点：</span>
        <p style="text-indent:40px">整合目的是信息互联互通、资源共享、再重新分配利用，使其已有软硬件功能发挥最大化功效，避免重复建设。</p>
        <p style="text-indent:40px">城市音视频互联互通的成功建设，大大提高了工作效率和质量，增强政府应急和服务能力，建设不仅能有效地促进各市级部门、县（市、区）音视频建设，而且还提高社会公众的整体安全感，提高社会公众生活质量，同时带动经济和社会信息化迅猛发展。</p>
        `
            }, {
                subtitle: '火益-分布式数据采集系统 物联网+互联网（数据采集层）',
                mainBody: `<p style="text-indent:40px">通过对现实世界与虚拟世界的数据采集，现实世界基于IoT芯片、通信模组集成在设备中，采集设备的位置、环境、使用状态等信息，虚拟世界通过网络爬虫，模拟用户终端登录获取结构信息，并将相关的状态数据、生产数据通过网络传送至数据交换平台（参看共享数据交换中心）。平台分析设备是否被违约挪用、是否被损坏、使用周期是否合理和产能产量是否合理。最终实现从芯片模组、设备监控、通信连接、到业务平台、数据分析、状态预测的一体化解决方案。</p>
        <p style="text-indent:40px">该产品被使用在获取海量互联网数据及IoT项目中，其逻辑底层都是来自于对数据的获取，虚拟世界使用网络爬虫，而物理世界则是来自传感器耦合而成的具有通信与计算能力的微型终端中。公司的各类大数据应用、火益云等均有广泛使用，为客户带来巨大的数据价值。</p>`
            }, {
                subtitle: '火益-工作流引擎（服务层）',
                mainBody: `<p style="text-indent:40px">全自主研发的工作引擎，支持流程全生命周期管理，自主研发的可视化流程设计器，灵活配置实现跨系统的业务集成。</p>
        <p style="text-indent:40px">该产品被应用于公司含有流程的所有MIS类系统之中，在智慧园区、安全环保、OA办公、移动办公、会议系统等领域得到应用</p>
        <span style="font-weight:bold">产品特点：</span>
        <p style="text-indent:40px">1、自主可控的知识产权产品</p>
        <p style="text-indent:40px">2、支持跨服务集成</p>
        <p style="text-indent:40px">3、多样化流转控制</p>
        <p style="text-indent:40px">4、流程全生命周期管理</p>
        <p style="text-indent:40px">5、全方位功能扩展</p>
        `
            }, {
                subtitle: '火益-代码质量运行监控优化平台（服务层）',
                mainBody: `<p style="text-indent:40px">信息系统运维监控平台，可从多个方面提升运维管理能力。具备以下几个方面的能力提升：</p>
        <p style="text-indent:40px">1、代码质量提升，建设代码质量审查系统，对代码中的不安全、不规范、不合法、不标准等问题进行识别，评估项目质量分数，标识缺陷代码，提供规范代码参考。</p>
        <p style="text-indent:40px">2、系统性能提升，建立APM监控服务，对在线运行的系统进行实时监控，提供对整个应用链的性能跟踪，并且在单个应用内容下钻分析数据库及方法级的性能跟踪。</p>
        <p style="text-indent:40px">3、侵权代码识别，通过检查项目引用的依赖库，识别是否开源库，对代码进行查重比对，识别高重复代码。</p>
        <p style="text-indent:40px">4、安全更新提示，定期检查项目依赖库是否存在安全风险，并发送预警通知.</p>`
            }],
            solveList: [],
            bgList: {},
        };
    },
    methods: {
        openCase(index, item) {
            this.activeCase = index;
            item.mainBody = item.mainBody.replace(/\n/g, "<br/>");
            item.mainBody1 = item.mainBody1.replace(/\n/g, "<br/>");
            this.bgList = item;
            console.log(item);
        },
        alignCenter() {
            const w = document.body.clientWidth;
            const boxes = $(".cover-name")[0];
            if (boxes) {
                const boxWid = boxes.scrollWidth; //获取div的宽
                boxes.style.left = w / 2 - boxWid / 2 + "px";
            }
        },
        getSoluteData(type) {
            const kind = 0;
            if (type === undefined || type === null) {
                type = 0;
            } else {
                document.getElementById("details").scrollIntoView();
            }
            this.type = type;
            http
                .get("/industrySolutions/findAllWeb", {
                    params: {
                        type,
                        kind
                    },
                })
                .then((res) => {
                    this.solveList = res.content;
                    this.openCase(0, res.content[0]);
                });
        },
    },
    mounted() {
        const _this = this;
        _this.alignCenter();
        setTimeout(() => {
            let type = sessionStorage.getItem('type');
            _this.getSoluteData(type);
        }, );
        window.onresize = function() {
            _this.alignCenter();
        };
    },
    beforeDestroy() {
        sessionStorage.removeItem('type');
    },
    computed: {},
    watch: {
        $route() {
            setTimeout(() => {
                let type = sessionStorage.getItem('type');
                this.getSoluteData(type);
            }, );
        }
    }
};